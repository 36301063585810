import { AuthLayout } from '@aims-layout'
import Head from 'next/head'
import { LoginForm } from '@aims-auth/components'
import React from 'react'

const Login = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>AIMS App: Log in</title>
      </Head>
      <AuthLayout>
        <LoginForm />
      </AuthLayout>
    </>
  )
}

export default Login
